// 
// user.scss
// Use this to write your custom SCSS
//
body {
  position: relative;
}

.intro-image {
  height: 650px;
}

.nav-item {
  .nav-link.active, .nav-link:hover {
    color: $nav-link-activer-color;
    &::before {
      display: block;
      position: absolute;
      top: -($navbar-padding-y + .0625);
      left: 1rem;
      width: calc(100% - 32px);
      height: 1px;
      background-color: $nav-link-activer-color;
      content: '';
    }
  }
}

#technologies {
  .owl-item {
    text-align: center;
    img {
      height: 40px;
    }
  }
}
